<template>
  <div class="grid justify-items-center p-5 sm:p-10 sm:mx-20">
    <div class="justify-self-center sm:justify-self-start space-x-2">
      <router-link to="/services/web">
        <button
          :class="[
            'p-2',
            'px-10',
            'border-2',
            'rounded',
            'font-semibold',
            'focus:outline-none',
            'border-gray-700',
            'hover:bg-gray-700',
            'hover:text-gray-50',
            'dark:border-gray-100',
            'dark:text-gray-100',
            'dark:hover:bg-gray-100',
            'dark:hover:text-cyan-1000',
            'transition-colors',
            'duration-500',
          ]"
        >
          Web
        </button>
      </router-link>
      <router-link to="/services/Desktop">
        <button
          :class="[
            'p-2',
            'px-10',
            'border-2',
            'rounded',
            'font-semibold',
            'focus:outline-none',
            'border-gray-700',
            'hover:bg-gray-700',
            'hover:text-gray-50',
            'dark:border-gray-100',
            'dark:text-gray-100',
            'dark:hover:bg-gray-100',
            'dark:hover:text-cyan-1000',
            'transition-colors',
            'duration-500',
          ]"
        >
          Desktop
        </button>
      </router-link>
    </div>
    <div
      class="flex justify-center items-center rounded-full bg-cyan-500 w-40 h-40"
    >
      <font-awesome-icon
        class="text-8xl text-gray-50 dark:text-cyan-1000 align-middle"
        :icon="['fas', 'mobile-alt']"
      />
    </div>
    <div class="mt-5 text-2xl font-bold dark:text-white">
      Mobile App Development
    </div>
    <div
      class="my-7 md:mx-30 lg:mx-36 dark:text-gray-50 text-center font-semibold md:text-xl text-lg"
    >
      Discover the world in your palm! We harness the power of portable
      computing and create modern and unique designs to provide consumers a
      brilliant and memorable user experience.
    </div>

    <div class="px-5">
      <router-link to="/contact-us">
        <button
          :class="[
            'p-2',
            'px-10',
            'border-2',
            'rounded',
            'font-semibold',
            'focus:outline-none',
            'border-gray-700',
            'hover:bg-gray-700',
            'hover:text-gray-50',
            'dark:border-gray-100',
            'dark:text-gray-100',
            'dark:hover:bg-gray-100',
            'dark:hover:text-cyan-1000',
            'transition-colors',
            'duration-500',
          ]"
        >
          Tell Us About Your Project !
        </button>
      </router-link>
    </div>

    <div
      class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 dark:text-white mt-7"
    >
      <div class="border-2 rounded-lg shadow-lg hover:shadow-2xl">
        <div class="flex flex-col p-3 mt-3">
          <div class="self-center relative">
            <font-awesome-icon
              class="absolute text-3xl ml-16 mt-9 transform -rotate-45 text-cyan-600"
              :icon="['fab', 'android']"
            />
            <font-awesome-icon
              class="text-8xl text-cyan-500"
              :icon="['fab', 'apple']"
            />
          </div>
          <div class="mb-5 self-center mt-10 text-xl font-bold">
            Cross Platform
          </div>
          <div class="p-5 leading-6 text-center">
            One code base, two platforms! Craft your ideas to life in an
            instance with reduced development costs and deliver it to users
            across both popular mobile platforms without having to go through
            indivivudal development lifecycles. Make changes in one code base,
            experience wonders on both platforms!
          </div>
        </div>
      </div>
      <div class="border-2 rounded-lg shadow-lg hover:shadow-2xl">
        <div class="flex flex-col p-3 mt-3">
          <div class="self-center relative">
            <font-awesome-icon
              class="text-8xl text-cyan-500"
              :icon="['fas', 'rocket']"
            />
            <div class="absolute transform -mt-5 -rotate-45">
              <div class="w-6 h-0.5 bg-cyan-500"></div>
              <div class="mt-1 w-6 h-0.5 bg-cyan-500"></div>
              <div class="mt-1 w-6 h-0.5 bg-cyan-500"></div>
            </div>
          </div>
          <div class="mb-5 self-center mt-10 text-xl font-bold">
            Performance
          </div>
          <div class="p-5 leading-6 text-center">
            Our applications are optimized to run faster and smoother to engage
            more audience across a variety of mobile devices. Efficient
            applications that execute effortlessly lead to less frustrated and
            more happy users.
          </div>
        </div>
      </div>
      <div class="border-2 rounded-lg shadow-lg hover:shadow-2xl">
        <div class="flex flex-col p-3 mt-3">
          <div class="self-center">
            <font-awesome-icon
              class="text-8xl text-cyan-500"
              :icon="['fas', 'paint-brush']"
            />
          </div>
          <div class="mb-5 self-center mt-10 text-xl font-bold">Design</div>
          <div class="p-5 leading-6 text-center">
            A modern and flawless design is the recipe to a successful mobile
            application. This is why we craft our designs with utmost attention
            to detail and user-friendliness to ensure an unparalleled user
            experience across all mobile platforms.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileService",
};
</script>

<style></style>
