<template>
  <div class="grid justify-items-center p-5 sm:p-10 sm:mx-20">
    <div class="justify-self-center sm:justify-self-start space-x-2">
      <router-link to="/services/mobile">
        <button
          :class="[
            'p-2',
            'px-10',
            'border-2',
            'rounded',
            'font-semibold',
            'focus:outline-none',
            'border-gray-700',
            'hover:bg-gray-700',
            'hover:text-gray-50',
            'dark:border-gray-100',
            'dark:text-gray-100',
            'dark:hover:bg-gray-100',
            'dark:hover:text-cyan-1000',
            'transition-colors',
            'duration-500',
          ]"
        >
          Mobile
        </button>
      </router-link>
      <router-link to="/services/Desktop">
        <button
          :class="[
            'p-2',
            'px-10',
            'border-2',
            'rounded',
            'font-semibold',
            'focus:outline-none',
            'border-gray-700',
            'hover:bg-gray-700',
            'hover:text-gray-50',
            'dark:border-gray-100',
            'dark:text-gray-100',
            'dark:hover:bg-gray-100',
            'dark:hover:text-cyan-1000',
            'transition-colors',
            'duration-500',
          ]"
        >
          Desktop
        </button>
      </router-link>
    </div>

    <div
      class="relative flex justify-center items-center rounded-full bg-cyan-500 w-40 h-40"
    >
      <div class="h-24 w-28 rounded-lg bg-gray-50 dark:bg-cyan-1000"></div>
      <font-awesome-icon
        class="absolute text-3xl -mt-16 ml-16 text-cyan-500 align-middle"
        :icon="['fas', 'ellipsis-h']"
      />
      <div class="absolute h-0.5 w-28 -mt-12 bg-cyan-500"></div>
      <font-awesome-icon
        class="absolute text-4xl text-cyan-500 mt-6 align-middle"
        :icon="['fas', 'code']"
      />
    </div>
    <div class="mt-5 text-center text-2xl font-bold dark:text-white">
      Web Application Development
    </div>
    <div
      class="my-7 md:mx-30 lg:mx-36 dark:text-gray-50 text-center font-semibold md:text-xl text-lg"
    >
      We bring the internet to your disposal with enriched, modern and powerful
      websites, tailored to your needs. Our websites are designed to be fast,
      highly responsive and trendy to engage users and bring business and
      traffic to you.
    </div>

    <div class="px-5">
      <router-link to="/contact-us">
        <button
          :class="[
            'p-2',
            'px-10',
            'border-2',
            'rounded',
            'font-semibold',
            'focus:outline-none',
            'border-gray-700',
            'hover:bg-gray-700',
            'hover:text-gray-50',
            'dark:border-gray-100',
            'dark:text-gray-100',
            'dark:hover:bg-gray-100',
            'dark:hover:text-cyan-1000',
            'transition-colors',
            'duration-500',
          ]"
        >
          Tell Us About Your Project !
        </button>
      </router-link>
    </div>

    <div
      class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-items-center gap-5 dark:text-white mt-7"
    >
      <div class="border-2 rounded-lg shadow-lg hover:shadow-2xl">
        <div class="flex flex-col p-3 mt-3">
          <div class="self-center relative">
            <font-awesome-icon
              class="absolute text-6xl ml-20 bg-gray-50 dark:bg-cyan-1000 rounded-full mt-10 text-cyan-600"
              :icon="['fas', 'mobile-alt']"
            />
            <font-awesome-icon
              class="absolute text-7xl bg-gray-50 dark:bg-cyan-1000 rounded-full -ml-8 mt-7 text-cyan-600"
              :icon="['fas', 'tablet-alt']"
            />
            <font-awesome-icon
              class="text-8xl text-cyan-500"
              :icon="['fas', 'desktop']"
            />
          </div>
          <div class="mb-5 self-center mt-10 text-xl font-bold">Responsive</div>
          <div class="p-5 leading-6 text-center">
            With a variety of devices and displays in the market, your website
            should look equally stunning on all. We take care of this need by
            designing fully responsive websites that adapt to the layout they
            are displayed on
          </div>
        </div>
      </div>
      <div class="border-2 rounded-lg shadow-lg hover:shadow-2xl">
        <div class="flex flex-col p-3 mt-3">
          <div class="self-center relative">
            <font-awesome-icon
              class="text-8xl text-cyan-500"
              :icon="['fas', 'rocket']"
            />
            <div class="absolute transform -mt-5 -rotate-45">
              <div class="w-6 h-0.5 bg-cyan-500"></div>
              <div class="mt-1 w-6 h-0.5 bg-cyan-500"></div>
              <div class="mt-1 w-6 h-0.5 bg-cyan-500"></div>
            </div>
          </div>
          <div class="mb-5 self-center mt-10 text-xl font-bold">
            Performance
          </div>
          <div class="p-5 leading-6 text-center">
            Websites are doing more and more by the day, and this means that the
            need to keep them effecient is growing. We design web applications
            that ensure fast loading speeds, jitterless smooth interaction and
            an unparalleled visual experience.
          </div>
        </div>
      </div>
      <div class="border-2 rounded-lg shadow-lg hover:shadow-2xl">
        <div class="flex flex-col p-3 mt-3">
          <div class="self-center">
            <font-awesome-icon
              class="text-8xl text-cyan-500"
              :icon="['fas', 'paint-brush']"
            />
          </div>
          <div class="mb-5 self-center mt-10 text-xl font-bold">
            Visualization
          </div>
          <div class="p-5 leading-6 text-center">
            We leverage modern web frameworks and elaborate styling libraries to
            pave the way for websites that have a modern and classy outlook.
            Whether it be forms, charts or tables, we use the best visuals and
            designs to beautify your website and bring it to life.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WebService",
};
</script>

<style></style>
