<template>
  <div class="grid justify-items-center p-5 sm:p-10 sm:mx-20">
    <div class="justify-self-center sm:justify-self-start space-x-2">
      <router-link to="/services/mobile">
        <button
          :class="[
            'p-2',
            'px-10',
            'border-2',
            'rounded',
            'font-semibold',
            'focus:outline-none',
            'border-gray-700',
            'hover:bg-gray-700',
            'hover:text-gray-50',
            'dark:border-gray-100',
            'dark:text-gray-100',
            'dark:hover:bg-gray-100',
            'dark:hover:text-cyan-1000',
            'transition-colors',
            'duration-500',
          ]"
        >
          Mobile
        </button>
      </router-link>
      <router-link to="/services/web">
        <button
          :class="[
            'p-2',
            'px-10',
            'border-2',
            'rounded',
            'font-semibold',
            'focus:outline-none',
            'border-gray-700',
            'hover:bg-gray-700',
            'hover:text-gray-50',
            'dark:border-gray-100',
            'dark:text-gray-100',
            'dark:hover:bg-gray-100',
            'dark:hover:text-cyan-1000',
            'transition-colors',
            'duration-500',
          ]"
        >
          Web
        </button>
      </router-link>
    </div>
    <div
      class="flex justify-center items-center rounded-full bg-cyan-500 w-40 h-40"
    >
      <font-awesome-icon
        class="text-8xl text-gray-50 dark:text-cyan-1000 align-middle"
        :icon="['fas', 'desktop']"
      />
    </div>
    <div class="mt-5 text-2xl font-bold dark:text-white text-center">
      Desktop Application Development
    </div>
    <div
      class="my-7 md:mx-30 lg:mx-36 dark:text-gray-50 text-center font-semibold md:text-xl text-lg"
    >
      Evolve your business by offering dedicated Desktop Applications for your
      solutions. We build modern and powerful desktop applications using popular
      development frameworks to cater to the large-screen audience
    </div>

    <div class="px-5">
      <router-link to="/contact-us">
        <button
          :class="[
            'p-2',
            'px-10',
            'border-2',
            'rounded',
            'font-semibold',
            'focus:outline-none',
            'border-gray-700',
            'hover:bg-gray-700',
            'hover:text-gray-50',
            'dark:border-gray-100',
            'dark:text-gray-100',
            'dark:hover:bg-gray-100',
            'dark:hover:text-cyan-1000',
            'transition-colors',
            'duration-500',
          ]"
        >
          Tell Us About Your Project !
        </button>
      </router-link>
    </div>

    <div
      class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 dark:text-white mt-7"
    >
      <div class="border-2 rounded-lg shadow-lg hover:shadow-2xl">
        <div class="flex flex-col p-3 mt-3">
          <div class="self-center relative rounded-full">
            <font-awesome-icon
              class="absolute text-6xl -ml-6 text-cyan-500"
              :icon="['fab', 'apple']"
            />
            <font-awesome-icon
              class="absolute text-6xl ml-16 mt-1 text-cyan-600"
              :icon="['fab', 'windows']"
            />
            <font-awesome-icon
              class="absolute text-8xl mt-8 text-cyan-500"
              :icon="['fab', 'linux']"
            />
            <font-awesome-icon
              class="text-8xl text-cyan-500 opacity-0"
              :icon="['fab', 'linux']"
            />
          </div>
          <div class="mb-5 self-center mt-10 text-xl font-bold">
            Cross Platform
          </div>
          <div class="p-5 leading-6 text-center">
            One code base, multiple platforms! Craft your ideas to life in an
            instance with reduced development costs and deliver it to users
            across multiple popular desktop operating systems, without having to
            go through indivivudal development lifecycles. Make changes in one
            code base, experience wonders on multiple platforms!
          </div>
        </div>
      </div>
      <div class="border-2 rounded-lg shadow-lg hover:shadow-2xl">
        <div class="flex flex-col p-3 mt-3">
          <div class="self-center relative">
            <font-awesome-icon
              class="text-8xl text-cyan-500"
              :icon="['fas', 'rocket']"
            />
            <div class="absolute transform -mt-5 -rotate-45">
              <div class="w-6 h-0.5 bg-cyan-500"></div>
              <div class="mt-1 w-6 h-0.5 bg-cyan-500"></div>
              <div class="mt-1 w-6 h-0.5 bg-cyan-500"></div>
            </div>
          </div>
          <div class="mb-5 self-center mt-10 text-xl font-bold">
            Performance
          </div>
          <div class="p-5 leading-6 text-center">
            Our softwares are designed for optimal performance, no matter the
            platform. Whether you're Mac user, a Windows user, or you've adapted
            to a flavor of Linux, your software will continue to perform
            flawlessly everywhere. Complex architectures are handled modularly
            to deliver a smooth and seamless experience.
          </div>
        </div>
      </div>
      <div class="border-2 rounded-lg shadow-lg hover:shadow-2xl">
        <div class="flex flex-col p-3 mt-3">
          <div class="self-center">
            <font-awesome-icon
              class="text-8xl text-cyan-500"
              :icon="['fas', 'paint-brush']"
            />
          </div>
          <div class="mb-5 self-center mt-10 text-xl font-bold">Design</div>
          <div class="p-5 leading-6 text-center">
            Desktop applications no longer need to look like ancient pieces of
            computer code from the 90s. We use cutting-edge technology and all
            the latest trends to bring your desktop application to life and
            allow it to stand out in terms of visuals and design.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DesktopService",
};
</script>

<style></style>
