<template>
  <div class="flex flex-col px-5">
    <div class="text-4xl mt-5 ml-2 font-semibold dark:text-gray-50">
      Our Services
    </div>
    <div class="grid relative mt-10">
      <div class="absolute bg-gray-200 w-full h-full rounded-3xl"></div>
      <div
        class="absolute w-full h-full rounded-3xl transform bg-gradient-to-br from-cyan-400 to-cyan-600 -rotate-1 sm:-rotate-2"
      ></div>
      <div
        class="p-8 sm:p-10 z-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4"
      >
        <div
          class="flex flex-col rounded-xl shadow-xl bg-white dark:bg-cyan-1000 dark:text-gray-100
          transition duration-200 ease-in-out transform hover:-translate-y-3"
        >
          <router-link to="/services/web">
            <div class="p-7 sm:p-14">
              <div class="w-full sm:w-1/2 text-xl font-bold pb-5">
                Web App Development
              </div>
              <div>
                We bring the internet to your disposal with enriched, modern and
                powerful websites, tailored to your needs. Our websites are
                designed to be fast, highly responsive and trendy to engage
                users and bring business and traffic to you.
              </div>
            </div>
          </router-link>
        </div>
        <div
          class="flex flex-col rounded-xl shadow-xl bg-white dark:bg-cyan-1000 dark:text-gray-100
          transition duration-200 ease-in-out transform hover:-translate-y-3"
        >
          <router-link to="/services/mobile">
            <div class="p-7 sm:p-14">
              <div class="w-full sm:w-1/2 text-xl font-bold pb-5">
                Mobile App Development
              </div>
              <div>
                Discover the world in your palm! We harness the power of
                portable computing and create modern and unique designs to
                provide consumers a brilliant and memorable user experience.
              </div>
            </div>
          </router-link>
        </div>
        <div
          class="flex flex-col md:col-span-2 lg:col-span-1 rounded-xl shadow-xl bg-white dark:bg-cyan-1000 dark:text-gray-100
          transition duration-200 ease-in-out transform hover:-translate-y-3"
        >
          <router-link to="/services/desktop">
            <div class="p-7 sm:p-14">
              <div class="w-full sm:w-1/2 text-xl font-bold pb-5">
                Desktop App Development
              </div>
              <div>
                Evolve your business by offering dedicated Desktop Applications
                for your solutions. We build modern and powerful desktop
                applications using popular development frameworks to cater to
                the large-screen audience
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="text-4xl mt-12 ml-2 font-semibold dark:text-gray-50">
      Our Priorities
    </div>

    <div
      class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 dark:text-white mt-7"
    >
      <div class="border-2 rounded-lg shadow-lg hover:shadow-2xl">
        <div class="flex flex-col p-3 mt-3">
          <div class="self-center">
            <font-awesome-icon
              class="text-8xl text-cyan-500"
              :icon="['fas', 'ruler-combined']"
            />
          </div>
          <div class="mb-5 self-center mt-10 text-xl font-bold">
            Scalability
          </div>
          <div class="p-5 leading-6 text-center">
            Organizations and businesses evolve, and so do projects. We use
            appropriate technologies and software stacks that support rapid
            devolopment and evolution of software to meet changing digital
            dynamics of the future
          </div>
        </div>
      </div>
      <div class="border-2 rounded-lg shadow-lg hover:shadow-2xl">
        <div class="flex flex-col p-3 mt-3">
          <div class="self-center">
            <font-awesome-icon
              class="text-8xl text-cyan-500"
              :icon="['fas', 'shield-alt']"
            />
          </div>
          <div class="mb-5 self-center mt-10 text-xl font-bold">Security</div>
          <div class="p-5 leading-6 text-center">
            We believe privacy and security are fundamental to a healthy digital
            products. That is why we make sure that the products we develop are
            well secured and follow established security protocols for a better
            professional reputation.
          </div>
        </div>
      </div>
      <div class="border-2 rounded-lg shadow-lg hover:shadow-2xl">
        <div class="flex flex-col p-3 mt-3">
          <div class="self-center">
            <font-awesome-icon
              class="text-8xl text-cyan-500"
              :icon="['fas', 'pencil-ruler']"
            />
          </div>
          <div class="mb-5 self-center mt-10 text-xl font-bold">
            Customizability
          </div>
          <div class="p-5 leading-6 text-center">
            No two applications are the same. We understand that, and we strive
            to ensure that your requirements are catered to. We offer full
            customizability in our products to enable unique design development
            and maximum client satisfaction
          </div>
        </div>
      </div>
      <div class="border-2 rounded-lg shadow-lg hover:shadow-2xl">
        <div class="flex flex-col p-3 mt-3">
          <div class="self-center relative">
            <font-awesome-icon
              class="text-8xl text-cyan-500"
              :icon="['fas', 'lightbulb']"
            />
            <font-awesome-icon
              class="absolute text-4xl text-cyan-500"
              :icon="['fas', 'shield-alt']"
            />
          </div>
          <div class="mb-5 self-center mt-10 text-xl font-bold">
            Confedentiality
          </div>
          <div class="p-5 leading-6 text-center">
            Ideas belong to the ideator. We believe in client confedentiality
            and make sure that the ideas you share with us, remain between us.
            We only help you being your idea to life. We also believe that the
            best relationships built are the ones built on trust.
          </div>
        </div>
      </div>
      <div class="border-2 rounded-lg shadow-lg hover:shadow-2xl">
        <div class="flex flex-col p-3 mt-3">
          <div class="self-center">
            <font-awesome-icon
              class="text-8xl text-cyan-500"
              :icon="['fas', 'life-ring']"
            />
          </div>
          <div class="mb-5 self-center mt-10 text-xl font-bold">Support</div>
          <div class="p-5 leading-6 text-center">
            Technologies keep evolving and so do the needs and requirements of
            softwares. We provide support and maintanence for extended periods
            to ensure that our clients can rely on the products we build
          </div>
        </div>
      </div>
      <div class="border-2 rounded-lg shadow-lg hover:shadow-2xl">
        <div class="flex flex-col p-3 mt-3">
          <div class="self-center">
            <font-awesome-icon
              class="text-8xl text-cyan-500"
              :icon="['fas', 'handshake']"
            />
          </div>
          <div class="mb-5 self-center mt-10 text-xl font-bold">
            Connections
          </div>
          <div class="p-5 leading-6 text-center">
            Whether you are here for one project or several, we would love to
            establish a connection of mutual respect and trust with you. We
            prioritize healthy human interactions more than our business and we
            always look forward to establishing long term connections with our
            clients.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import ServiceCard from "../components/ServiceCard";
export default {
  name: "Services",
  // components: { ServiceCard },
  data: function() {
    return {
      services: [
        {
          title: "Web Application Development",
          anim: "",
          summary: `We bring the internet to your disposal with enriched, modern and powerful websites, tailored to your needs.
            Our websites are designed to be fast, highly responsive and trendy to engage users and bring business and
            traffic to you.`,
        },
        {
          title: "Mobile Development",
          anim: "",
          summary: `Discover the world in your palm! We harness the power of portable computing and create modern and
          unique designs to provide consumers a brilliant and memorable user experience.`,
        },
        {
          title: "Business Software Development",
          anim: "",
          summary: ``,
        },
        {
          title: "Blockchain Technologies",
          anim: "",
          summary: `The world is moving towards decentralization and so are we. We bring to you power and innovation,
            building everyday solutions on Dapps using decentralized architectures for improved transparency and
            fault tolerance.`,
        },
      ],
    };
  },
};
</script>

<style scoped></style>
